<style lang="sass" scoped>
.link-channel
  a
    font-weight: 300

    &.active
      font-weight: 700
</style>
<template lang="pug">
div
  .p-4
    h1 Table 대체
    div.shadow.my-4
      .row
        .col-3.border-right.bg-dark
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 고객목록
            .mb-3
            .pl-3.link-channel
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem') #
                | 전체
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem') #
                | 예약자 대기
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem') #
                | 예약자 확정
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem') #
                | 예약자 취소대기
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem') #
                | 예약자 취소완료
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 레코드
            .mb-3
            .pl-3.link-channel
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem')
                  b-icon-card-list
                | 메시지
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem')
                  b-icon-card-list
                | 투어상품
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem')
                  b-icon-card-list
                | 가이드
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem')
                  b-icon-card-list
                | 투어일정(가이드스케쥴)
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem')
                  b-icon-card-list
                | 가이드OFF 문의폼
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 라이브
            .mb-3
            .pl-3.link-channel
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem')
                  b-icon-wifi
                | db.users
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem')
                  b-icon-wifi
                | db.pg_payments
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem')
                  b-icon-wifi
                | db.wp_comments
              a.d-block.text-white.mt-1(href='#')
                strong(style='margin-right:1rem')
                  b-icon-wifi
                | db.users_point

          .pt-4
          .pt-4

        .col
          .p-4
            p 내용

    div.shadow.my-4
      .row
        .col-3.border-right.bg-dark
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 고객목록
            .mb-3


          .pt-4
          .pt-4

        .col
          .p-4
            //- h5 전체

            div.border-bottom.pb-2
              strong Search criteria
              a.float-right(href='#') Options

            div.shadow-sm.p-3.mt-2.border
              .row
                .col
                  strong.d-block
                    | Primary
                    span.text-muted.ml-2 Primary
                    span.text-muted.ml-2 Primary
                  small.text-muted.mr-2 Secondary
                  small.text-muted.mr-2 Secondary
                  small.text-muted.mr-2 Secondary
                  br
                  .mt-3
                  span.badge.badge-light.border.mr-2.py-2 RELATIONS
                  span.badge.badge-light.border.mr-2.py-2 RELATIONS
                .col(style='font-size: 12px')
                  div.bg-light(style='overflow: scroll; height: 10rem')
                    p.text-center(style='padding-top: 4rem') Note
            div.shadow-sm.p-3.mt-2.border
              .row
                .col
                  strong.d-block
                    | Primary
                    span.text-muted.ml-2 Primary
                    span.text-muted.ml-2 Primary
                  small.text-muted.mr-2 Secondary
                  small.text-muted.mr-2 Secondary
                  small.text-muted.mr-2 Secondary
                  br
                  .mt-3
                  span.badge.badge-light.border.mr-2.py-2 RELATIONS
                  span.badge.badge-light.border.mr-2.py-2 RELATIONS
                .col(style='font-size: 12px')
                  div.bg-light(style='overflow: scroll; height: 10rem')
                    p.text-center(style='padding-top: 4rem') Note

    .my-4.text-white ~
    .my-4.text-white ~
    .my-4.text-white ~

    div.shadow.my-4
      .row
        .col-3.border-right.bg-dark
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 고객목록
            .mb-3


          .pt-4
          .pt-4

        .col
          .p-4
            //- h5 전체

            div.border-bottom.pb-2
              strong 1701건 중 100건
              a.float-right(href='#') 검색조건

            div.shadow-sm.p-3.mt-2.border
              .row
                .col
                  strong.d-block
                    | 이진혁
                    span.text-muted.ml-2 [예약자 대기]
                    span.text-muted.ml-2 010-4102-9921
                  small.text-muted.mr-2 주문명: 괌비치투어/3인/1월성수기/마리트
                  small.text-muted.mr-2 선결제금액: 56,000
                  small.text-muted.mr-2 가이드전달메모: 친구여행입니다. 픽업요청드립니다. 감사합니다.
                  br
                  .mt-2
                  span.badge.badge-light.border.mr-1 가이드 13 한가람
                  span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                .col(style='font-size: 12px')
                  div(style='overflow: scroll; height: 10rem')
                    div.mb-1
                      strong.mr-1 이진혁
                      span 마리트
                    div.mb-1
                      strong.mr-1 김손손
                      span 가이드배정했습니다.
                    div
                      strong.mr-1 김손손
                      span 문의인입 // 1인 추가하여 4인으로 변경원하시는대 현장결제 또는 계좌이체 가능한가요?
                    div.text-success.py-2.text-center
                      strong.mr-1 김손손
                      strong Case Open
                    div
                      strong.mr-1 김손손
                      span 카드결제 완료 db.pg_payments id=3331
                    div
                      strong.mr-1 김손손
                      span 가이드 전달완료
                    div.text-danger.py-2.text-center.bg-light
                      strong.mr-1 김손손
                      strong Case Closed

            div.shadow-sm.p-3.mt-2.border
              .row
                .col
                  strong.d-block
                    | 이진혁
                    span.text-muted.ml-2 [예약자 대기]
                    span.text-muted.ml-2 010-4102-9921
                  small.text-muted.mr-2 주문명: 괌비치투어/3인/1월성수기/마리트
                  small.text-muted.mr-2 선결제금액: 56,000
                  small.text-muted.mr-2 가이드전달메모: 친구여행입니다. 픽업요청드립니다. 감사합니다.
                  br
                  .mt-2
                  //- span.badge.badge-light.border.mr-1 가이드 13 한가람
                  //- span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                .col(style='font-size: 12px')
                  div(style='overflow: scroll; height: 10rem')
                    div.mb-1
                      strong.mr-1 이진혁
                      span 추가함
            div.shadow-sm.p-3.mt-2.border
              .row
                .col
                  strong.d-block
                    | 이진혁
                    span.text-muted.ml-2 [예약자 대기]
                    span.text-muted.ml-2 010-4102-9921
                  small.text-muted.mr-2 주문명: 괌비치투어/3인/1월성수기/마리트
                  small.text-muted.mr-2 선결제금액: 56,000
                  small.text-muted.mr-2 가이드전달메모: 친구여행입니다. 픽업요청드립니다. 감사합니다.
                  br
                  .mt-2
                  span.badge.badge-light.border.mr-1 가이드 13 한가람
                  span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                  div.bg-white.text-white.shadow-sm(style='position: relative; width: 170px; left: 0px; top: 3px')
                    .btn-group.btn-block
                      button.btn.btn-light.border 검색
                      button.btn.btn-light.border 열기(새창)
                .col(style='font-size: 12px')
                  div(style='overflow: scroll; height: 10rem')
                    div.mb-1
                      strong.mr-1 이진혁
                      span 추가함



    .my-4.text-white ~
    .my-4.text-white ~
    .my-4.text-white ~



    div.shadow.my-4
      .row
        .col-3.border-right.bg-dark
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 고객목록
            .mb-3


          .pt-4
          .pt-4

        .col
          .row
            .col-4.border-right
              div.mt-2
                .row
                  .col
                    strong.d-block
                      | 이진혁
                      span.text-muted.ml-2 [예약자 대기]
                      span.text-muted.ml-2 010-4102-9921
                    small.text-muted.mr-2 주문명: 괌비치투어/3인/1월성수기/마리트
                    small.text-muted.mr-2 선결제금액: 56,000
                    small.text-muted.mr-2 가이드전달메모: 친구여행입니다. 픽업요청드립니다. 감사합니다.
                    br
                    .mt-2
                    span.badge.badge-light.border.mr-1 가이드 13 한가람
                    span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
              hr(style='margin: 1rem -15px')
              div.mt-2
                .row
                  .col
                    strong.d-block
                      | 이진혁
                      span.text-muted.ml-2 [예약자 대기]
                      span.text-muted.ml-2 010-4102-9921
                    small.text-muted.mr-2 주문명: 괌비치투어/3인/1월성수기/마리트
                    small.text-muted.mr-2 선결제금액: 56,000
                    small.text-muted.mr-2 가이드전달메모: 친구여행입니다. 픽업요청드립니다. 감사합니다.
                    br
                    .mt-2
                    //- span.badge.badge-light.border.mr-1 가이드 13 한가람
                    //- span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
              hr(style='margin: 1rem -15px')
              div.mt-2
                .row
                  .col
                    strong.d-block
                      | 이진혁
                      span.text-muted.ml-2 [예약자 대기]
                      span.text-muted.ml-2 010-4102-9921
                    small.text-muted.mr-2 주문명: 괌비치투어/3인/1월성수기/마리트
                    small.text-muted.mr-2 선결제금액: 56,000
                    small.text-muted.mr-2 가이드전달메모: 친구여행입니다. 픽업요청드립니다. 감사합니다.
                    br
                    .mt-2
                    //- span.badge.badge-light.border.mr-1 가이드 13 한가람
                    //- span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
              hr(style='margin: 1rem -15px')
            .col
              h5 sdf

    div.shadow.my-4
      .row
        .col-3.border-right.bg-dark
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 고객목록
            .mb-3


          .pt-4
          .pt-4

        .col
          h5 dd
          div(style='width: calc(95vw - 350px); overflow: scroll;')
            table.table(style='width: 1500px')
              thead
                tr
                  td(style='') 고객명
                  td(style='') 상태
                  td(style='') 연락처
                  td(style='') 주문명
                  td(style='') 선결제금액
                  td(style='') 가이드전달메모
                  td(style='')
                    b-icon-card-list.mr-1
                    | 가이드
                  td(style='')
                    b-icon-card-list.mr-1
                    | 투어상품
              tbody
                tr
                  td 이진혁
                  td [예약자대기]
                  td 010-4102-9921
                  td 괌비치투어/3인/1월성수기/마리트
                  td 56,000
                  td 친구여행입니다. 픽업요청드립니다. 감사합니다.
                  td
                    span.badge.badge-light.border.mr-1 가이드 13 한가람
                  td
                    span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                tr
                  td 이진혁
                  td [예약자대기]
                  td 010-4102-9921
                  td 괌비치투어/3인/1월성수기/마리트
                  td 56,000
                  td 친구여행입니다. 픽업요청드립니다. 감사합니다.
                  td
                    span.badge.badge-light.border.mr-1 가이드 13 한가람
                  td
                    span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                tr
                  td 이진혁
                  td [예약자대기]
                  td 010-4102-9921
                  td 괌비치투어/3인/1월성수기/마리트
                  td 56,000
                  td 친구여행입니다. 픽업요청드립니다. 감사합니다.
                  td
                    span.badge.badge-light.border.mr-1 가이드 13 한가람
                  td
                    span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치


    .my-4.text-white ~
    .my-4.text-white ~
    .my-4.text-white ~



    div.shadow.my-4
      .row
        .col-3.border-right.bg-dark
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 고객목록
            .mb-3


          .pt-4
          .pt-4

        .col
          h5 dd

          table.table
            thead
              tr
                td(style='width: 300px') 제목
                td(style='') 내용
                td(style='')
                  b-icon-card-list.mr-1
                  | 연결된 레코드
                td(style='') 담당자메모
            tbody
              tr
                td
                  strong 이진혁
                    span.text-muted.ml-2 [예약자 대기]
                    span.text-muted.ml-2 010-4102-9921
                td
                  strong.d-block 주문명
                  small.text-muted 괌비치투어/3인/1월성수기/마리트
                  .mt-1
                  strong.d-block 선결제금액
                  small.text-muted 56,000
                  .mt-1
                  strong.d-block 가이드전달메모
                  small.text-muted 친구여행입니다. 픽업요청드립니다. 감사합니다.
                td
                  span.badge.badge-light.border.mr-1 가이드 13 한가람
                  span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                td
                  div(style='overflow: scroll; height: 10rem')
                    div.mb-1
                      strong.mr-1 이진혁
                      span 마리트
                    div.mb-1
                      strong.mr-1 김손손
                      span 가이드배정했습니다.
                    div
                      strong.mr-1 김손손
                      span 문의인입 // 1인 추가하여 4인으로 변경원하시는대 현장결제 또는 계좌이체 가능한가요?
                    div.text-success.py-2.text-center
                      strong.mr-1 김손손
                      strong Case Open
                    div
                      strong.mr-1 김손손
                      span 카드결제 완료 db.pg_payments id=3331
                    div
                      strong.mr-1 김손손
                      span 가이드 전달완료
                    div.text-danger.py-2.text-center.bg-light
                      strong.mr-1 김손손
                      strong Case Closed
              tr
                td
                  strong 이진혁
                    span.text-muted.ml-2 [예약자 대기]
                    span.text-muted.ml-2 010-4102-9921
                td
                  strong.d-block 주문명
                  small.text-muted 모하비사막/6인/마리트
                  .mt-1
                  strong.d-block 선결제금액
                  small.text-muted 356,000
                  .mt-1
                  strong.d-block 가이드전달메모
                  small.text-muted 과거에 그랜드캐니언에서 실족한적이 있습니다.
                td
                  span.badge.badge-light.border.mr-1 가이드 53 박가람
                  span.badge.badge-light.border.mr-1 투어상품 53 사막투어
                td
                  div(style='overflow: scroll; height: 10rem')
                    div.mb-1
                      strong.mr-1 이진혁
                      span 마리트
                    div.mb-1
                      strong.mr-1 김손손
                      span 가이드배정했습니다.
              tr
                td
                  strong 이진혁
                    span.text-muted.ml-2 [예약자 대기]
                    span.text-muted.ml-2 010-4102-9921
                td
                  strong.d-block 주문명
                  small.text-muted 속초물회투어/4인/마리트
                  .mt-1
                  strong.d-block 선결제금액
                  small.text-muted 90,000
                  .mt-1
                  strong.d-block 가이드전달메모
                  small.text-muted 유투브 촬영 예정이에요. 구독과 좋아요 부탁드려요.
                td
                  span.badge.badge-light.border.mr-1 가이드 105 김옥자
                  span.badge.badge-light.border.mr-1 투어상품 130 속초시장 물회사랑꾼
                td
                  div(style='overflow: scroll; height: 10rem')
                    div.mb-1
                      strong.mr-1 이진혁
                      span 마리트

    div.shadow.my-4
      .row
        .col-3.border-right.bg-dark
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 고객목록
            .mb-3


          .pt-4
          .pt-4

        .col
          h5 dd

          table.table
            thead
              tr
                td(style='width: 300px') 제목
                td(style='') 내용
                td(style='')
                  b-icon-card-list.mr-1
                  | 연결된 레코드
                td(style='width: 300px') 담당자메모
            tbody
              tr
                td
                  strong 알고리즘트레빗
                    span.text-muted.ml-2 7568323
                    span.text-muted.ml-2 hello@gmail.com
                td
                  strong.d-block 사업자번호
                  small.text-muted 000-00-000000
                  .mt-1
                  strong.d-block 대표자명
                  small.text-muted 김고리
                  .mt-1
                  strong.d-block 연락처/직함
                  small.text-muted 010-0000-0000
                  .mt-1
                  strong.d-block MM 참여여부
                  small.text-muted Y
                  .mt-1
                  strong.d-block BTC 비율
                  small.text-muted 89.5
                  //- .mt-1
                  //- strong.d-block
                  //-   | 월거래량 10 (억)
                  //-   span.badge.badge-info SQL
                  //- //- small.text-muted 8900.55
                  //- button.btn.btn-sm.btn-light 8900.55
                  .mt-1
                  strong.d-block
                    | 월거래량 09 (억)
                    span.badge.badge-info SQL
                  //- small.text-muted 8900.55
                  span.text-muted: u 8900.55
                  small.text-muted.ml-2 5분전 가져옴
                  .mt-1
                  strong.d-block
                    | 월거래량 09 (억)
                    span.badge.badge-info SQL
                  b-spinner(label='가져오는중' small variant='secondary')

                td

                td
                  div(style='overflow: scroll; height: 10rem')
                    div.mb-1
                      strong.mr-1 안은영
                      span 010-0000-0000 30억 거래 예치 문의. 리워드 0.5-0.65 안내


    .my-4.text-white ~
    .my-4.text-white ~
    .my-4.text-white ~

    div.shadow.my-4
      .row
        .col-3.border-right.bg-dark
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 고객목록
            .mb-3


          .pt-4
          .pt-4

        .col
          h5 dd

          table.table
            tbody
              tr
                th 고유번호
                td
                  a(href='#') 33003
                  span.ml-4 공개범위:ALL
                  span.ml-4 수정가능:ALL

              tr
                th 회사명
                td
                  input.form-control(value='알고리즘트레빗')
              tr
                th 회원번호
                td
                  input.form-control(value='7568323')
              tr
                th 계정아이디
                td
                  input.form-control(value='hello@gmail.com')
              tr
                th 가이드
                td
                  div.shadow-sm.p-3.mt-2.border
                    strong.d-block
                      | 한가람
                      span.text-muted.ml-2 ID:13
                      span.text-muted.ml-2 010-9999-8888
                    small.text-muted.mr-2 위치: 괌
                    small.text-muted.mr-2 계약여부: Y
                    small.text-muted.mr-2 연락시간(국내기준): 오전 7시-오후4시
                    br
                    .mt-2
                    span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                    span.badge.badge-light.border.mr-1 투어상품 54 Han's 괌비치비치 럭셔리
                    span.badge.badge-light.border.mr-1 투어상품 55 Han's 괌비치비치 짠내투어
                    div.bg-white.text-white.shadow-sm(style='position: relative; width: 170px; left: 0px; top: 3px')
                      .btn-group.btn-block
                        button.btn.btn-light.border 펼치기
                        button.btn.btn-light.border 열기(새창)
              tr
                th 투어상품
                td
                  div.shadow-sm.p-3.mt-2.border
                    strong.d-block
                      | Han's 괌비치비치 짠내투어
                      span.text-muted.ml-2 ID:55
                      span.text-muted.ml-2 괌/6인
                    small.text-muted.mr-2 수수료: 10
                    br
                    .mt-2
                    span.badge.badge-light.border.mr-1 투어일정(가이드스케쥴) ID:110 2021-02
                    span.badge.badge-light.border.mr-1 투어일정(가이드스케쥴) ID:110 2021-01
                    span.badge.badge-light.border.mr-1 투어일정(가이드스케쥴) ID:110 2020-12
                    div.bg-white.text-white.shadow-sm(style='position: relative; width: 170px; left: 210px; top: 3px')
                      .btn-group.btn-block
                        button.btn.btn-light.border 펼치기
                        button.btn.btn-light.border 열기(새창)
              tr
                th 투어상품
                td
                  div.shadow-sm.p-3.mt-2.border
                    strong.d-block
                      | Han's 괌비치비치 짠내투어
                      span.text-muted.ml-2 ID:55
                      span.text-muted.ml-2 괌/6인
                    small.text-muted.mr-2 수수료: 10
                    br
                    .mt-2
                    span.badge.badge-light.border.p-2.mr-1 투어일정(가이드스케쥴) ID:110 2021-02
                    span.badge.badge-primary.shadow.p-2.border.mr-1 투어일정(가이드스케쥴) ID:110 2021-01
                    span.badge.badge-light.border.p-2.mr-1 투어일정(가이드스케쥴) ID:110 2020-12

                    div.shadow.mt-2.border
                      table.table
                        tbody
                          tr
                            th 스케쥴 이름
                            td
                              input.form-control(value='2020-12')
                          tr
                            th 내용
                            td
                              textarea.form-control(rows=10).
                                월화 목금 4주 모두 가능 //
                                3일전 미리 연락주면 주말도 오픈가능
                          tr
                            th 가이드
                            td
                              div.shadow-sm.p-3.mt-2.border
                                strong.d-block
                                  | 한가람
                                  span.text-muted.ml-2 ID:13
                                  span.text-muted.ml-2 010-9999-8888
                                small.text-muted.mr-2 위치: 괌
                                small.text-muted.mr-2 계약여부: Y
                                small.text-muted.mr-2 연락시간(국내기준): 오전 7시-오후4시
                                br
                                .mt-2
                                span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                                span.badge.badge-light.border.mr-1 투어상품 54 Han's 괌비치비치 럭셔리
                                span.badge.badge-light.border.mr-1 투어상품 55 Han's 괌비치비치 짠내투어

    .my-4.text-white ~
    .my-4.text-white ~
    .my-4.text-white ~

    div.shadow.my-4
      .row
        .col-3.border-right.bg-dark
          .pt-4.text-white
            b-icon-chevron-down.mr-2
            strong 고객목록
            .mb-3


          .pt-4
          .pt-4

        .col
          h5 dd

          table.table
            tbody
              tr
                th.bg-light.text-right 고유번호
                td
                  a(href='#') 33003
                  span.ml-4 공개범위:ALL
                  span.ml-4 수정가능:ALL

              tr
                th.bg-light.text-right 회사명
                td
                  input.form-control(value='알고리즘트레빗')
              tr
                th.bg-light.text-right 회원번호
                td
                  input.form-control(value='7568323')
              tr
                th.bg-light.text-right 계정아이디
                td
                  input.form-control(value='hello@gmail.com')
              tr
                th.bg-light.text-right 가이드
                td
                  div.shadow-sm.p-3.mt-2.border
                    strong.d-block
                      | 한가람
                      span.text-muted.ml-2 ID:13
                      span.text-muted.ml-2 010-9999-8888
                    small.text-muted.mr-2 위치: 괌
                    small.text-muted.mr-2 계약여부: Y
                    small.text-muted.mr-2 연락시간(국내기준): 오전 7시-오후4시
                    br
                    //- .mt-2
                    //- span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                    //- span.badge.badge-light.border.mr-1 투어상품 54 Han's 괌비치비치 럭셔리
                    //- span.badge.badge-light.border.mr-1 투어상품 55 Han's 괌비치비치 짠내투어
                  div.shadow-sm.p-3.mt-2.border
                    strong.d-block
                      | 박가람
                      span.text-muted.ml-2 ID:14
                      span.text-muted.ml-2 010-7777-8888
                    small.text-muted.mr-2 위치: 괌
                    small.text-muted.mr-2 계약여부: Y
                    small.text-muted.mr-2 연락시간(국내기준): 오전 7시-오후4시
                    br
                    //- .mt-2
                    //- span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                    //- span.badge.badge-light.border.mr-1 투어상품 54 Han's 괌비치비치 럭셔리
                    //- span.badge.badge-light.border.mr-1 투어상품 55 Han's 괌비치비치 짠내투어
              tr
                th.bg-light.text-right 가이드
                td
                  span.badge.badge-light.border.p-2.mr-1 가이드 ID:13 한가람
                  span.badge.badge-primary.shadow.p-2.border.mr-1 가이드 ID:14 박가람
                  //- div.shadow.p-3.mt-2.border.bg-primary.text-white
                    strong.d-block
                      | 박가람
                      span.text-light.ml-2 ID:14
                      span.text-light.ml-2 010-7777-8888
                    small.text-light.mr-2 위치: 괌
                    small.text-light.mr-2 계약여부: Y
                    small.text-light.mr-2 연락시간(국내기준): 오전 7시-오후4시
                    br
                    //- .mt-2
                    //- span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                    //- span.badge.badge-light.border.mr-1 투어상품 54 Han's 괌비치비치 럭셔리
                    //- span.badge.badge-light.border.mr-1 투어상품 55 Han's 괌비치비치 짠내투어
                  div.shadow-sm.mt-2.border
                    table.table
                      tbody
                        tr
                          th.bg-light.text-right 고유번호
                          td
                            a(href='#') 13
                        tr
                          th.bg-light.text-right 이름
                          td
                            input.form-control(value='한가람')
                        tr
                          th.bg-light.text-right 연락처
                          td
                            input.form-control(value='2423')
                        tr
                          th.bg-light.text-right 연결된 레코드
                          td
                            b-tabs(pills)
                              b-tab(title='전체')
                                h1 내용
                                h1 내용
                                h1 내용
                              b-tab(title='가이드')
                                h1 내용
                                h1 내용!!
                                h1 내용
                              b-tab(title='투어일정(가이드스케쥴)')
                                h1 내용
                                h1 내용
                                h1 내용?
                              b-tab(title='후기')
                                h1 내용?
                                h1 내용
                                h1 내용
                              b-tab(title='정산서')
                                h1 내용
                                h1 내용?
                                h1 내용

              tr
                th.bg-light.text-right 투어상품
                td
                  div.shadow-sm.p-3.mt-2.border
                    strong.d-block
                      | Han's 괌비치비치 짠내투어
                      span.text-muted.ml-2 ID:55
                      span.text-muted.ml-2 괌/6인
                    small.text-muted.mr-2 수수료: 10
                    br
                    .mt-2
                    span.badge.badge-light.border.p-2.mr-1 투어일정(가이드스케쥴) ID:110 2021-02
                    span.badge.badge-primary.shadow.p-2.border.mr-1 투어일정(가이드스케쥴) ID:110 2021-01
                    span.badge.badge-light.border.p-2.mr-1 투어일정(가이드스케쥴) ID:110 2020-12

                    div.shadow.mt-2.border
                      table.table
                        tbody
                          tr
                            th 스케쥴 이름
                            td
                              input.form-control(value='2020-12')
                          tr
                            th 내용
                            td
                              textarea.form-control(rows=10).
                                월화 목금 4주 모두 가능 //
                                3일전 미리 연락주면 주말도 오픈가능
                          tr
                            th 가이드
                            td
                              div.shadow-sm.p-3.mt-2.border
                                strong.d-block
                                  | 한가람
                                  span.text-muted.ml-2 ID:13
                                  span.text-muted.ml-2 010-9999-8888
                                small.text-muted.mr-2 위치: 괌
                                small.text-muted.mr-2 계약여부: Y
                                small.text-muted.mr-2 연락시간(국내기준): 오전 7시-오후4시
                                br
                                .mt-2
                                span.badge.badge-light.border.mr-1 투어상품 53 Han's 괌비치비치
                                span.badge.badge-light.border.mr-1 투어상품 54 Han's 괌비치비치 럭셔리
                                span.badge.badge-light.border.mr-1 투어상품 55 Han's 괌비치비치 짠내투어
              tr
                th.bg-light.text-right 연결된 레코드
                td
                  b-tabs(pills)
                    b-tab(title='전체')
                      h1 내용
                      h1 내용
                      h1 내용
                    b-tab(title='가이드')
                      h1 내용
                      h1 내용!!
                      h1 내용
                    b-tab(title='투어일정(가이드스케쥴)')
                      h1 내용
                      h1 내용
                      h1 내용?
                    b-tab(title='후기')
                      h1 내용?
                      h1 내용
                      h1 내용
                    b-tab(title='정산서')
                      h1 내용
                      h1 내용?
                      h1 내용




    h1 import/export/api
    h1 live sql, event log

</template>

<script>

export default {
  name: 'sample',
  components: {

  },
  data() {
    return {
      done: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.done = true
    }, 300);
  }
}
</script>
